import React from 'react';
import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {SocialIcon} from './SocialIcon/SocialIcon';
import {SocialVendor} from '../../constants';
import {withGlobalProps, ProvidedGlobalProps, withDirection} from '../../providers/globalPropsProvider';
import {
  FacebookSocialNetwork,
  SocialShareService,
  GoogleSocialNetwork,
  TwitterSocialNetwork,
  PinterestSocialNetwork,
  FancySocialNetwork,
  MobileNativeShareStringObject,
} from '../../commons/SocialShareService/SocialShareService';
import style from './ProductSocial.scss';
import {getDefaultProductMedia, shouldRenderVendorFromStyledParams} from './ProductSocialUtils';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IMedia} from '../../types/productDef';

export interface ProductSocialProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  isLiveSiteMode?: boolean;
}

const socialNetworks = {
  [SocialVendor.Facebook]: FacebookSocialNetwork,
  [SocialVendor.Google]: GoogleSocialNetwork,
  [SocialVendor.Twitter]: TwitterSocialNetwork,
  [SocialVendor.Pinterest]: PinterestSocialNetwork,
  [SocialVendor.Fancy]: FancySocialNetwork,
};

@withGlobalProps
@withTranslations('globals.texts')
@withDirection
export class ProductSocial extends React.Component<ProductSocialProps> {
  public static defaultProps = {
    isLiveSiteMode: true,
  };

  private readonly shouldUseMobileNativeShare = () => {
    const {experiments, isMobile} = this.props.globals;
    return isMobile && experiments.isMobileNativeShareEnabled && 'share' in navigator;
  };

  private readonly openSocialShareWindow = (shareType: SocialVendor) => {
    const {product, productUrl} = this.props.globals;

    if (this.shouldUseMobileNativeShare() && shareType === SocialVendor.MobileNative) {
      const mobileNativeShareData: MobileNativeShareStringObject = {
        title: product.name,
        text: product.description,
        url: productUrl,
      };
      (window.navigator as any).share(mobileNativeShareData);
    } else {
      const vendor = new socialNetworks[shareType]();
      const productImage = getDefaultProductMedia(product) as IMedia;
      const options = {asJpg: false, isSEOBot: true};
      const imageUrl = getMediaUrl(productImage, productImage, {}, options);
      const socialShareUrl = new SocialShareService(vendor).getSocialNetworkUrl({
        title: product.name,
        url: productUrl,
        imageUrl,
      });

      window.open(socialShareUrl, '_blank', 'width=700, height=600, top=300, left=300, chrome=yes, centerscreen');
    }
  };

  public onClick = (socialVendor: SocialVendor) => {
    if (!this.props.isLiveSiteMode) {
      return window.alert('Social share only works on live sites');
    }

    const {
      globals: {
        socialSharing: {onClick: controllerCallback},
      },
    } = this.props;

    controllerCallback({socialButton: socialVendor});
    return this.openSocialShareWindow(socialVendor);
  };

  private readonly renderVendorIcon = (vendor: SocialVendor) => {
    const {t} = this.props;

    return (
      <SocialIcon
        key={`social-sharing-${vendor}`}
        type={vendor}
        label={vendor === SocialVendor.MobileNative ? 'Share' : t(`SHARE_TITLE_${vendor.toUpperCase()}`)}
        onClick={() => this.onClick(vendor)}
        className={style.icon}
      />
    );
  };

  public render() {
    let socialNetworksShouldRender = Object.keys(socialNetworks).filter((vendor: SocialVendor) =>
      shouldRenderVendorFromStyledParams(vendor, this.props)
    );
    if (this.shouldUseMobileNativeShare() && socialNetworksShouldRender.length > 0) {
      socialNetworksShouldRender = [SocialVendor.MobileNative];
    }

    return (
      <div data-hook="social-sharing" className={style.root}>
        {socialNetworksShouldRender.map(this.renderVendorIcon)}
      </div>
    );
  }
}
